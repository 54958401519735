<template>
  <section
    class="nav-avatar-section"
  >
    <avatar
      :username="userName"
      background-color="#FFC107"
      :size="75"
      :rounded="true"
      :lighten="50"
    />
    <div
      class="nav-avatar-info"
    >
      <div
        class="user-name"
      >
        {{ userName }}
      </div>
    </div>
    <div
      class="buttons"
    >
      <button
        class="button is-small"
        @click="logout"
      >Log Out
      </button>
    </div>
    <div
      class="nav-avatar-info"
      v-if="showTenantScope"
    >
      <div
        class="user-scope"
      >
        <b-field label="Tenant Scope">
          <b-button @click="clearAppTenantScope" icon-right="delete" size="is-small">{{appTenantScope}}</b-button>
        </b-field>
      </div>
    </div>
    <div
      class="nav-avatar-info"
      v-if="showUserScope"
    >
      <div
        class="user-scope"
      >
        <b-button @click="stopImpersonating" icon-right="delete" size="is-small">Stop Impersonating</b-button>
      </div>
    </div>
    <div
      class="nav-avatar-info"
      v-if="showGraphqlErrors"
    >
      <div
        class="user-scope"
      >
        <b-button @click="navToGraphqlErrors" size="is-small" class="is-danger">GQL Errors</b-button>
      </div>
    </div>
    <div
      class="nav-avatar-info"
      v-if="showResetDemoData"
    >
      <div
        class="user-scope"
      >
        <b-button @click="resetDemoData" size="is-small" class="is-danger">Reset Demo Data</b-button>
      </div>
    </div>
    <div
      class="nav-avatar-info"
      v-if="showLibrarySelector"
    >
      <div
        class="user-scope"
      >
        <b-select size="is-small" placeholder="Select library..." v-model="currentWorkingLibraryId" @input="librarySelected">
          <option
            v-for="l in libraries"
            :value="l.id"
            :key="l.id"
          >
            {{ l.id }}
          </option>
        </b-select>
      </div>
    </div>
    <div
      class="nav-avatar-info"
      v-else
    >
      <div
        class="user-scope"
      >
        {{currentWorkingLibraryId}}
      </div>
    </div>
  </section>
</template>

<script>
import script from './script/index'
export default script
</script>

<style scoped lang="scss" src="./style/_styles.scss" />
