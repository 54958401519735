import Avatar from 'vue-avatar'
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'MainNavAvatar',
  components: {
    Avatar
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo'
    }),
    userName () {
      return `${this.userInfo.firstName} ${this.userInfo.lastName}`
    },
    showTenantScope () {
      return (this.$store.state.user.scopeAppTenant !== null)
    },
    appTenantScope () {
      return this.$store.state.user.scopeAppTenant ? this.$store.state.user.scopeAppTenant.name : null
    },
    showUserScope () {
      return (
        this.$store.state.user.actualAppUserInfo !== null &&
        this.$store.state.user.actualAppUserInfo.appUserId !== this.$store.state.user.userInfo.appUserId &&
        (this.$store.state.user.scopeAppUserInfo ? this.$store.state.user.scopeAppUserInfo.appUserId !== this.$store.state.user.userInfo.appUserId : true)
      )
    },
    showResetDemoData () {
      return this.$store.state.user.userInfo.username === 'fnb-demo'
    },
    showGraphqlErrors () {
      return this.$userHasPermission('p:super-admin') && this.$store.state.app.recordedExceptions.length > 0
    },
    libraries () {
      return (this.$store.state.user.userInfo.libraries || []).reduce(
        (a, l) => {
          return a.find(al => al.id === l.id) ? a : [...a, l]
        }, []
      ) || []
    },
    showLibrarySelector () {
      return this.libraries.length > 0 && this.$userHasPermission('p:super-admin')
    },
    workingLibrary () {
      return this.$store.state.ovb.workingLibrary
    },
    currentWorkingLibraryId () {
      return this.$store.state.ovb.workingLibrary ? this.$store.state.ovb.workingLibrary.id : null
    }
  },
  methods: {
    librarySelected (libraryId) {
      const library = this.libraries.find(l => l.id === libraryId)
      this.$store.dispatch('SET_WORKING_LIBRARY', library)
    },
    async logout () {
      this.$store.dispatch('brochure/setGuestUser', null)
      this.$store.dispatch('user/logout')
      this.$store.dispatch('app/logout')
      await axios.get('/post-logout')
      this.$store.dispatch('RESET_OVB')
        .then(() => {
          this.$router.push({ name: 'PreLogout' })
        })
    },
    clearAppTenantScope () {
      this.$store.dispatch('CLEAR_SCOPE_APP_TENANT')
    },
    stopImpersonating () {
      this.$store.dispatch('STOP_IMPERSONATION')
    },
    navToGraphqlErrors () {
      this.$store.commit('app/setEntityAppState', {
        entityId: 'OVB',
        type: 'Application',
        detailTabIndex: 3
      })
      if (this.$router.currentRoute.name !== 'ApplicationManager') {
        this.$router.push({ name: 'ApplicationManager' })
      }
    },
    resetDemoData () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to reset demo data?',
        title: 'Reset Demo Data',
        onConfirm: () => {
          this.$store.dispatch('RESET_DEMO_DATA')
        },
        reject: () => {
        }
      })
    }
  }
}
