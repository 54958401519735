import router from '@/router'
import apolloProvider from '@/graphql'
import getAppUserInfo from '@/graphql/query/getAppUserInfo.graphql'
import initAppTenantSupport from '@/graphql/query/initAppTenantSupport.graphql'
import initDemo from '@/graphql/mutation/initDemo.graphql'
import initSubsidiaryAdmin from '@/graphql/mutation/initSubsidiaryAdmin.graphql'
import resetDemoData from '@/graphql/mutation/resetDemoData.graphql'
import signCurrentEula from '@/graphql/mutation/signCurrentEula.graphql'

import getSupportEmail from '@/graphql/query/supportEmail.graphql'

const types = {
  RESET_USER: 'RESET_USER',
  SET_USER: 'SET_USER',
  SET_APP_USER: 'SET_APP_USER',
  SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
  SET_SUPPORT_EMAIL: 'SET_SUPPORT_EMAIL',
  SET_SCOPE_APP_TENANT: 'SET_SCOPE_APP_TENANT',
  CLEAR_SCOPE_APP_TENANT: 'CLEAR_SCOPE_APP_TENANT',
  SET_SCOPE_USER: 'SET_SCOPE_USER',
  SET_ACTUAL_USER: 'SET_ACTUAL_USER',
  IMPERSONATE_USER: 'IMPERSONATE_USER',
  STOP_IMPERSONATION: 'STOP_IMPERSONATION',
  SET_SCOPE_PERMISSIONS: 'SET_SCOPE_PERMISSIONS',
  BECOME_ACTUAL_USER: 'BECOME_ACTUAL_USER',
  BECOME_SUPPORT_USER: 'BECOME_SUPPORT_USER',
  INIT_DEMO: 'INIT_DEMO',
  INIT_SUBSIDIARY_ADMIN: 'INIT_SUBSIDIARY_ADMIN',
  RESET_DEMO_DATA: 'RESET_DEMO_DATA',
  SIGN_CURRENT_EULA: 'SIGN_CURRENT_EULA',
  SET_SHOW_OLD_USERS: 'SET_SHOW_OLD_USERS',
  SET_IMPERSONATION_REDIRECT: 'SET_IMPERSONATION_REDIRECT',
  USER_HAS_PERMISSION: 'USER_HAS_PERMISSION',
  SET_USER_DEVICE: 'SET_USER_DEVICE',
  SET_CALIBRATION_ENABLED: 'SET_CALIBRATION_ENABLED',
  SET_IS_NEW_USER: 'SET_IS_NEW_USER',
  SET_TUTORIALS_STATUS: 'SET_TUTORIALS_STATUS',
  SET_LICENSE_TYPE_KEY: 'SET_LICENSE_TYPE_KEY' // adding because "isHomePatient" does not seem to work
}

const getDefaultState = () => {
  return {
    appUser: {}, // userInfo is not including createdAt, so setting it up
    userInfo: {},
    supportEmail: 'help@ourvisualbrain.com',
    isLoggedIn: false,
    // userDevice is stored in a persistent cookie,
    // this value is grabbed from the cookie on login if the cookie exists
    userDevice: null,
    scopeAppTenant: null,
    // scopePermissions: [],
    actualAppUserInfo: null,
    scopeAppUserInfo: null,
    showOldUsers: true,
    isNewUser: false,
    impersonationRedirectUrl: null,
    calibrationEnabled: false,
    isHomePatient: false,
    tutorialsStatus: null,
    licenseTypeKey: null // adding because "isHomePatient" does not seem to work
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  appUser: state => state.appUser, // userInfo is not including createdAt, so setting it up
  isNewUser: state => state.isNewUser,
  userInfo: state => state.userInfo, // all user info from OBV on a big blob
  actualAppUserInfo: state => state.actualAppUserInfo, // all user info from OBV on a big blob
  userId: state => state.userInfo.id, // just the id
  userPermissions: state => state.userInfo.permissions, // just the permissions
  isLoggedIn: state => state.isLoggedIn,
  scopeAppTenant: state => state.scopeAppTenant,
  userDevice: state => state.userDevice,
  calibrationEnabled: state => state.calibrationEnabled,
  isHomePatient: state => (state.actualAppUserInfo === null),
  tutorialsStatus: state => state.tutorialsStatus,
  licenseTypeKey: state => state.licenseTypeKey // adding because "isHomePatient" does not seem to work

}

// actions
const actions = {
  reset ({ commit }) { // reset the user
    commit('types.RESET_USER')
  },
  async setUser ({ commit, dispatch }, payload) { // everything from OVB on this user
    commit(types.SET_USER, payload)
    commit(types.SET_IS_LOGGED_IN, true)
    const library = payload.libraries[0]
    dispatch(
      'SET_WORKING_LIBRARY',
      library,
      { root: true }
    )
  },
  setLicenseTypeKey ({ commit }, payload) {
    commit(types.SET_LICENSE_TYPE_KEY, payload)
  },
  setTutorialsStatus ({ commit }, payload) {
    commit(types.SET_TUTORIALS_STATUS, payload)
  },
  async checkHousekeepingTasks ({ commit, dispatch }, payload) { // everything from OVB on this user
    // support email
    const emailResult = await apolloProvider.defaultClient
      .query({
        query: getSupportEmail,
        fetchPolicy: 'cache-first'
      })
      .catch(() => {
        // do nothing
      })
    const supportEmail = emailResult.data.supportEmail
    commit(types.SET_SUPPORT_EMAIL, supportEmail)
  },
  setAppUser ({ commit }, payload) {
    commit(types.SET_APP_USER, payload)
  },
  setIsNewUser ({ commit }, payload) {
    commit(types.SET_IS_NEW_USER, payload)
  },
  setUserToken ({ commit }, payload) {
    commit(types.SET_USER_TOKEN, payload)
  },
  setCalibrationEnabled ({ commit }, payload) {
    commit(types.SET_CALIBRATION_ENABLED, payload)
  },
  setUserDevice ({ commit }, payload) {
    commit(types.SET_USER_DEVICE, payload)
  },
  logout ({ commit }) {
    commit(types.RESET_USER, null)
  },
  [types.SET_SCOPE_APP_TENANT]: {
    root: true,
    handler: async (store, payload) => {
      // alert(JSON.stringify(payload, null, 2))
      const appTenant = payload.appTenant || payload
      const routerPath = payload.routerPath || { name: 'ClinicDashboard' }
      const result = await apolloProvider.defaultClient
        .query({
          query: initAppTenantSupport,
          variables: {
            appTenantId: appTenant.id
          },
          fetchPolicy: 'networkOnly'
        })

      store.commit(types.SET_SCOPE_USER, {
        ...result.data.initAppTenantSupport
      })
      store.commit(types.SET_SCOPE_APP_TENANT, appTenant)
      store.commit(types.IMPERSONATE_USER, result.data.initAppTenantSupport)

      // also will want to do libraries here...
      const libraryId = result.data.initAppTenantSupport.permissions.filter(p => p.indexOf('l:') === 0)[0].split(':')[1]
      const library = result.data.initAppTenantSupport.libraries.find(l => l.id === libraryId)
      store.dispatch('SET_WORKING_LIBRARY',
        library,
        { root: true }
      )
      router.push(routerPath)
    }
  },
  [types.INIT_DEMO]: {
    root: true,
    handler: async (store, payload) => {
      const result = await apolloProvider.defaultClient
        .mutate({
          mutation: initDemo,
          variables: {
            licensePackKey: payload.licensePackKey
          }
        })

      store.commit(types.SET_SCOPE_USER, {
        ...result.data.initDemo.initDemoResult.appUserAuth0Info
      })
      store.commit(types.SET_SCOPE_APP_TENANT, result.data.initDemo.initDemoResult.demoAppTenant)
      store.commit(types.IMPERSONATE_USER, result.data.initDemo.initDemoResult.appUserAuth0Info)

      // also will want to do libraries here...
      const libraryId = result.data.initDemo.initDemoResult.appUserAuth0Info.permissions.filter(p => p.indexOf('l:') === 0)[0].split(':')[1]
      const library = result.data.initDemo.initDemoResult.appUserAuth0Info.libraries.find(l => l.id === libraryId)
      store.dispatch('SET_WORKING_LIBRARY',
        library,
        { root: true }
      )
      router.push({ name: 'ClinicDashboard' })
    }
  },
  [types.INIT_SUBSIDIARY_ADMIN]: {
    root: true,
    handler: async (store, payload) => {
      const result = await apolloProvider.defaultClient
        .mutate({
          mutation: initSubsidiaryAdmin,
          variables: payload
        })

      store.commit(types.SET_SCOPE_USER, {
        ...result.data.initSubsidiaryAdmin.initSubsidiaryAdminResult.appUserAuth0Info
      })
      store.commit(types.SET_SCOPE_APP_TENANT, result.data.initSubsidiaryAdmin.initSubsidiaryAdminResult.subsidiaryAppTenant)
      store.commit(types.IMPERSONATE_USER, result.data.initSubsidiaryAdmin.initSubsidiaryAdminResult.appUserAuth0Info)

      // also will want to do libraries here...
      // console.log(JSON.stringify(result.data.initDemo.initDemoResult.appUserAuth0Info.permissions, null, 2))
      // const libraryId = result.data.initDemo.initDemoResult.appUserAuth0Info.permissions.filter(p => p.indexOf('l:') === 0)[0].split(':')[1]
      // const library = result.data.initDemo.initDemoResult.appUserAuth0Info.libraries.find(l => l.id === libraryId)
      // store.dispatch('SET_WORKING_LIBRARY',
      //   library,
      //   { root: true }
      // )
      router.push({ name: 'ClinicDashboard' })
    }
  },
  [types.IMPERSONATE_USER]: {
    root: true,
    handler: async (store, payload) => {
      // await store.commit(types.BECOME_ACTUAL_USER)
      if (['fnb-support', 'fnb-demo'].indexOf(store.state.userInfo.username) > -1) {
        await store.commit(types.BECOME_ACTUAL_USER)
      } else {
        // this would be the case where a therapist has clicked the In Clinic Play button
        // this is where we need to set the current machine if it is not set
        await store.commit(types.SET_ACTUAL_USER, store.state.userInfo)
      }

      const result = await apolloProvider.defaultClient.mutate({
        mutation: getAppUserInfo,
        variables: payload
      })
      const redirect = {
        name: router.currentRoute.name,
        params: router.currentRoute.params
      }
      await store.commit(types.SET_IMPERSONATION_REDIRECT, redirect)
      await store.commit(types.IMPERSONATE_USER, result.data.getAppUserInfo)
      router.push({ name: result.data.getAppUserInfo.homePath })
    }
  },
  [types.STOP_IMPERSONATION]: {
    root: true,
    handler: async (store, payload) => {
      store.commit(types.STOP_IMPERSONATION)
      const redirect = store.state.impersonationRedirectUrl || { name: 'ClinicDashboard' }
      router.push(redirect)
    }
  },
  [types.CLEAR_SCOPE_APP_TENANT]: {
    root: true,
    handler: async (store, payload) => {
      store.commit(types.CLEAR_SCOPE_APP_TENANT)
      if (store.state.userInfo.permissionKey === 'SuperAdmin') {
        router.push({ name: 'AppTenantManager' })
      } else {
        if (router.currentRoute.name === 'ClinicDashboard') {
          // store.commit('app/refreshKey', null, { root: true })
          router.go()
        } else {
          router.push({ name: 'ClinicDashboard' })
        }
      }
    }
  },
  [types.RESET_DEMO_DATA]: {
    root: true,
    handler: async (store, payload) => {
      apolloProvider.defaultClient
        .mutate({
          mutation: resetDemoData,
          variables: {
            appTenantId: store.state.scopeAppTenant.id
          }
        })
        .then(() => {
          alert('demo data has been reset')
          store.commit('app/refreshKey', null, { root: true })
        })
    }
  },
  [types.SIGN_CURRENT_EULA]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: signCurrentEula,
          variables: payload
        })
    }
  },
  [types.SET_SHOW_OLD_USERS]: {
    root: true,
    handler: async (store, payload) => {
      store.commit('SET_SHOW_OLD_USERS', payload)
    }
  },
  [types.USER_HAS_PERMISSION]: {
    root: true,
    handler: async (store, permissionKey) => {
      const permissionKeys = Array.isArray(permissionKey) ? permissionKey : [permissionKey]
      if (permissionKeys.length === 0) return true

      if (!store.state) return false
      if (!store.state.userInfo) return false
      if (!store.state.userInfo.permissions) return false

      const userPermissions = store.state.userInfo.permissions
      const found = userPermissions.some(r => permissionKeys.includes(r))
      return found
    }
  }
}

// mutations
const mutations = {
  [types.RESET_USER] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.SET_APP_USER] (state, payload) {
    state.appUser = payload
  },
  [types.SET_IS_NEW_USER] (state, payload) {
    state.isNewUser = payload
  },
  [types.SET_USER] (state, userInfo) {
    state.userInfo = userInfo
  },
  [types.SET_LICENSE_TYPE_KEY] (state, payload) {
    state.licenceTypeKey = payload
  },
  [types.SET_IS_LOGGED_IN] (state, payload) {
    state.isLoggedIn = payload
  },
  [types.SET_USER_DEVICE] (state, payload) {
    state.userDevice = payload
  },
  [types.SET_TUTORIALS_STATUS] (state, payload) {
    state.tutorialsStatus = payload
  },
  [types.SET_SUPPORT_EMAIL] (state, payload) {
    state.supportEmail = payload
  },
  [types.SET_SCOPE_APP_TENANT] (state, payload) {
    state.actualAppUserInfo = state.actualAppUserInfo || state.userInfo
    state.scopeAppTenant = payload
  },
  [types.CLEAR_SCOPE_APP_TENANT] (state) {
    state.userInfo = state.actualAppUserInfo
    state.scopeAppTenant = null
    state.actualAppUserInfo = null
  },
  [types.BECOME_ACTUAL_USER]: (state) => {
    state.userInfo = state.actualAppUserInfo
  },
  [types.SET_ACTUAL_USER]: (state, payload) => {
    state.actualAppUserInfo = payload
  },
  [types.BECOME_SUPPORT_USER]: (state) => {
    state.userInfo = state.scopeAppUserInfo
  },
  [types.SET_SCOPE_USER]: (state, payload) => {
    state.scopeAppUserInfo = payload
  },
  [types.IMPERSONATE_USER] (state, payload) {
    state.actualAppUserInfo = state.actualAppUserInfo || state.userInfo
    state.userInfo = payload
  },
  [types.SET_IMPERSONATION_REDIRECT] (state, payload) {
    state.impersonationRedirectUrl = payload
  },
  [types.STOP_IMPERSONATION] (state) {
    state.userInfo = state.scopeAppUserInfo || state.actualAppUserInfo
  },
  [types.SET_SHOW_OLD_USERS] (state, payload) {
    state.showOldUsers = payload
  },
  [types.SET_CALIBRATION_ENABLED] (state, payload) {
    state.calibrationEnabled = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
