// Page state for the primary "Activity Explorer" within the app
// list of all ActivitiesBrowser parent views
const parentViews = ['ActivityExplorer', 'ActivitiesView', 'PatientActivityManagement', 'ManageClinicActivityPlaylist']

const defaultLayoutValues = [
  {
    label: 'Grid',
    value: 'GRID',
    selected: true
  },
  {
    label: 'Table',
    value: 'TABLE',
    selected: false
  }
]

const defaultModalityValues = [
  {
    label: 'MFBF',
    value: 'supportsMfbf',
    selected: false
  },
  {
    label: 'Red/Green',
    value: 'supportsRedgreen',
    selected: false
  },
  {
    label: 'Full Color',
    value: 'supportsFullColor',
    selected: false
  }
]

const defaultOptionValues = [
  {
    label: 'Voice Recognition',
    value: 'supportsVoiceRecognition',
    selected: false
  },
  {
    label: 'Reduced Stimulus',
    value: 'supportsReducedStimulus',
    selected: false
  },
  {
    label: 'Dyslexic Format',
    value: 'supportsDyslexic',
    selected: false
  },
  {
    label: 'Processing Speed',
    value: 'supportsPssb',
    selected: false
  }
]
const cleanState = {
  name: null,
  selectedCategories: [],
  searchTerm: null,
  layoutValues: defaultLayoutValues,
  modalityValues: defaultModalityValues,
  optionValues: defaultOptionValues
}
const generatePageStates = () => {
  const pgStatesList = []
  parentViews.forEach((vw) => {
    const newState = { ...cleanState }
    newState.name = vw
    pgStatesList.push(newState)
  })
  return pgStatesList
}

const types = {
  SET_LAYOUT: 'SET_LAYOUT',
  SET_MODALITY_VALUES: 'SET_MODALITY_VALUES',
  SET_OPTION_VALUES: 'SET_OPTION_VALUES',
  SET_LAYOUT_VALUES: 'SET_LAYOUT_VALUES',
  SET_CATEGORY_SELECTIONS: 'SET_CATEGORY_SELECTIONS',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  CLEAR_STATE: 'CLEAR_STATE'
}

const getDefaultState = () => {
  return {
    pageStates: generatePageStates(),
    selectedCategories: [],
    searchTerm: null,
    layoutValues: [
      {
        label: 'Grid',
        value: 'GRID',
        selected: true
      },
      {
        label: 'Table',
        value: 'TABLE',
        selected: false
      }
    ],
    modalityValues: [
      {
        label: 'MFBF',
        value: 'supportsMfbf',
        selected: false
      },
      {
        label: 'Red/Green',
        value: 'supportsRedgreen',
        selected: false
      },
      {
        label: 'Full Color',
        value: 'supportsFullColor',
        selected: false
      }
    ],
    optionValues: [
      {
        label: 'Voice Recognition',
        value: 'supportsVoiceRecognition',
        selected: false
      },
      {
        label: 'Reduced Stimulus',
        value: 'supportsReducedStimulus',
        selected: false
      },
      {
        label: 'Dyslexic Format',
        value: 'supportsDyslexic',
        selected: false
      },
      {
        label: 'Processing Speed',
        value: 'supportsPssb',
        selected: false
      }
    ]
  }
}

// initial state
const state = getDefaultState()

const targetPageState = (state, viewName) => {
  return state.pageStates.find(tgtState => tgtState.name === viewName)
}
// apply state
const applyState = (state, viewName, tgtProp, payload) => {
  const tgtState = targetPageState(state, viewName)
  tgtState[tgtProp] = payload
}

// getters
const getters = {
  pageStates: state => state.pageStates
}

// actions
const actions = {
  setOptionValues ({ commit }, payload) {
    commit(types.SET_OPTION_VALUES, payload)
  },
  setModalityValues ({ commit }, payload) {
    commit(types.SET_MODALITY_VALUES, payload)
  },
  setLayoutValues ({ commit }, payload) {
    commit(types.SET_LAYOUT_VALUES, payload)
  },
  setCategorySelections ({ commit }, payload) {
    commit(types.SET_CATEGORY_SELECTIONS, payload)
  },
  setSearchTerm ({ commit }, payload) {
    commit(types.SET_SEARCH_TERM, payload)
  },
  clearState ({ commit }, viewName) {
    commit(types.CLEAR_STATE, viewName)
  }
}

// mutations
const mutations = {
  [types.SET_LAYOUT_VALUES] (state, payload) {
    applyState(state, payload.viewName, 'layoutValues', payload.data)
  },
  [types.SET_PAGE_STATE] (state, payload) {
    state.pageStates = payload
  },
  [types.SET_MODALITY_VALUES] (state, payload) {
    applyState(state, payload.viewName, 'modalityValues', payload.data)
  },
  [types.SET_OPTION_VALUES] (state, payload) {
    applyState(state, payload.viewName, 'optionValues', payload.data)
  },
  [types.SET_CATEGORY_SELECTIONS] (state, payload) {
    applyState(state, payload.viewName, 'selectedCategories', payload.data)
    // state.selectedCategories = payload
  },
  [types.SET_SEARCH_TERM] (state, payload) {
    applyState(state, payload.viewName, 'searchTerm', payload.data)
    // state.searchTerm = payload
  },
  [types.CLEAR_STATE] (state, viewName) {
    const tgtIndex = state.pageStates.findIndex(pgState => pgState.name === viewName)
    state.pageStates[tgtIndex].layoutValues = [...defaultLayoutValues]
    state.pageStates[tgtIndex].modalityValues = [...defaultModalityValues]
    state.pageStates[tgtIndex].optionValues = [...defaultOptionValues]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
