import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import createMutationsSharer from 'vuex-shared-mutations'
import mutations from './mutations'
import actions from './actions'
import application from './modules/application'
import sproutvideo from './modules/sproutvideo'
import notifications from './modules/notifications'
import user from './modules/user'
import ovb from './modules/ovb'
import hlp from './modules/hlp'
import brochure from './modules/brochure'
import billing from './modules/billing'
import projects from './modules/projects'
import activitiesBrowsers from './modules/activitiesBrowsers'
import worksheetsBrowsers from './modules/worksheetsBrowsers'
import videosBrowsers from './modules/videosBrowsers'
import app from './modules/app'
Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    refresh: false
  },
  mutations: mutations,
  actions: actions,
  modules: {
    application,
    user,
    app,
    ovb,
    hlp,
    billing,
    projects,
    brochure,
    sproutvideo,
    notifications,
    activitiesBrowsers,
    worksheetsBrowsers,
    videosBrowsers
  },
  plugins: [
    new VuexPersistence().plugin,
    createMutationsSharer({ predicate: ['ovb/SET_DEMO_SESSION', 'ovb/SET_CURRENT_ACTIVITY_EXPLORER_CONFIGURATION'] })
  ]
})
