import { gsap } from 'gsap'
import { Expo, ScrollToPlugin, CSSPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin, CSSPlugin)

export const goToRef = {
  methods: {
    goto (refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop
      gsap.to(window, 0.1, { scrollTo: { x: 0, y: top }, ease: Expo.easeInOut })
    },
    scrollToElement (el, offset) {
      const heightOffset = offset || 28
      const rect = el.getBoundingClientRect()
      const tgtY = (rect.top - rect.height - heightOffset) + window.scrollY
      gsap.to(window, 0.75, { scrollTo: { x: 0, y: tgtY }, ease: Expo.easeInOut })
    },
    scrollToTop () {
      const tgtY = window.scrollY * -1
      gsap.to(window, 0.75, { scrollTo: { x: 0, y: tgtY }, ease: Expo.easeInOut })
    },
    scrollToRefName (refName, offset) {
      const heightOffset = offset || 0
      const element = this.$refs[refName]
      const top = element.offsetTop - heightOffset
      gsap.to(window, 0.75, { scrollTo: { x: 0, y: top }, ease: Expo.easeInOut })
    },
    scrollToChildRef (refName, offset) {
      const heightOffset = offset || 0
      const parentTop = this.$refs[refName].offsetParent ? this.$refs[refName].offsetParent.offsetTop : 0
      const elementTop = this.$refs[refName].offsetTop
      const top = (parentTop + elementTop) - heightOffset
      gsap.to(window, 1, { scrollTo: { x: 0, y: top }, ease: Expo.easeInOut })
    }
  }
}

export const spaceDasher = {
  methods: {
    spaceDasher: function (val) {
      return val ? val.replace(/--/g, '').replace(/\s-\s/g, '-').replace(/\s+/g, '-').toLowerCase() : null
    }
  }
}
