const types = {
  SET_LIBRARY_INFO: 'SET_LIBRARY_INFO',
  SET_SELECTED_LIBRARY: 'SET_SELECTED_LIBRARY',
  SET_GUEST_USER: 'SET_GUEST_USER',
  SET_LICENSE_PLANS: 'SET_LICENSE_PLANS',
  SET_PRICING_TAG: 'SET_PRICING_TAG',
  SET_IS_HOME_PROGRAM: 'SET_IS_HOME_PROGRAM'
}

const getDefaultState = () => {
  return {
    libraryInfo: [],
    selectedLibrary: null,
    // guestUserInfo: this.assign({}, defaultGuestUser),
    guestUserInfo: {
      email: null,
      phone: null,
      company: null,
      lastName: null,
      firstName: null
    },
    licensePlans: null,
    pricingTag: null,
    isHomeProgram: false
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  libraryInfo: state => state.libraryInfo,
  selectedLibrary: state => state.selectedLibrary,
  guestUserInfo: state => state.guestUserInfo,
  licensePlans: state => state.licensePlans,
  pricingTag: state => state.pricingTag,
  isHomeProgram: state => state.isHomeProgram
}

// actions
const actions = {
  setLibraryInfo ({ commit }, payload) {
    commit(types.SET_LIBRARY_INFO, payload)
  },
  setLicensePlans ({ commit }, payload) {
    commit(types.SET_LICENSE_PLANS, payload)
  },
  setSelectedLibrary ({ commit }, payload) {
    commit(types.SET_SELECTED_LIBRARY, payload)
  },
  setPricingTag ({ commit }, payload) {
    commit(types.SET_PRICING_TAG, payload)
  },
  setGuestUser ({ commit }, payload) {
    commit(types.SET_GUEST_USER, payload)
  },
  setIsHomeProgram ({ commit }, payload) {
    commit(types.SET_IS_HOME_PROGRAM, payload)
  }
}

// mutations
const mutations = {
  [types.SET_LIBRARY_INFO] (state, payload) {
    state.libraryInfo = payload
  },
  [types.SET_LICENSE_PLANS] (state, payload) {
    state.licensePlans = payload
  },
  [types.SET_SELECTED_LIBRARY] (state, payload) {
    state.selectedLibrary = payload
  },
  [types.SET_GUEST_USER] (state, payload) {
    state.guestUserInfo = payload
  },
  [types.SET_PRICING_TAG] (state, payload) {
    state.pricingTag = payload
  },
  [types.SET_IS_HOME_PROGRAM] (state, payload) {
    state.isHomeProgram = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
