<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MainNavMenu',
  data () {
    return {
      // navSections: [
      //   {
      //     menuName: 'Clinic',
      //     permissionKey: 'm:clinic',
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'ClinicDashboard',
      //         menuName: 'Clinic Dashboard',
      //         class: 'nav-item',
      //         exact: true,
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ClinicPatients',
      //         menuName: 'Patient Roster',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ClinicTherapists',
      //         menuName: 'Therapist Roster',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ClinicNotificationManager',
      //         menuName: 'Notification Manager',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'QuickStart',
      //         menuName: 'OVB Quick Start',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       // {
      //       //   routeName: 'OldUsers',
      //       //   menuName: 'Old Users',
      //       //   class: 'nav-item',
      //       //   permissionFn: this.showOldUsers
      //       // },
      //       {
      //         routeName: 'ClinicSessionManager',
      //         menuName: 'Activity Sessions',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ActivityAssignmentReview',
      //         menuName: 'Assignment Review',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'HelpCenter',
      //         menuName: 'Help Center',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   },
      //   {
      //     menuName: 'Patient',
      //     permissionKey: 'm:patient',
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'PatientDashboard',
      //         menuName: 'Patient Dashboard',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   },
      //   {
      //     menuName: 'OVB Silver',
      //     permissionKey: 'm:silver',
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'GameRoomSilver',
      //         menuName: 'Game Room',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'GamesExplorer',
      //         menuName: 'Games Explorer',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   },
      //   {
      //     menuName: 'Clinic Trial',
      //     permissionKey: 'm:clinic-trial',
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'ClinicTrial',
      //         menuName: 'Trial Dashboard',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ClinicPricing',
      //         menuName: 'Pricing',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'PurchaseUpgrade',
      //         menuName: 'Purchase Upgrade',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'GamesExplorer',
      //         menuName: 'Games Explorer',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   },
      //   {
      //     menuName: 'OVB Administration',
      //     permissionKey: this.showSuperAdmin,
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'OvbDashboard',
      //         menuName: 'OVB Dashboard',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'SupportCenter',
      //         menuName: 'Support Center',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'DemoCenter',
      //         menuName: 'Demo Center',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'NotificationManager',
      //         menuName: 'Notification Manager',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'HelpCenterAdmin',
      //         menuName: 'Help Center',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       // {
      //       //   routeName: 'MessagingManager',
      //       //   menuName: 'Messaging'
      //       // },
      //       // {
      //       //   routeName: 'HelpTopicManager',
      //       //   menuName: 'Help Manager'
      //       // },
      //       {
      //         routeName: 'ActivitySessionExplorer',
      //         menuName: 'Activity Sessions',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ActivityErrors',
      //         menuName: 'Activity Errors',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'OldCustomers',
      //         menuName: 'Old Customers',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   },
      //   {
      //     menuName: 'OVB Content',
      //     permissionKey: ['p:super-admin', 'p:ovb-activity-developer'],
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'ActivityManager',
      //         menuName: 'Activity Manager',
      //         permissionKey: 'p:ovb-activity-developer',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'WeMoveManager',
      //         menuName: 'Video Manager',
      //         permissionKey: 'p:super-admin',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'WorksheetManager',
      //         menuName: 'Worksheet Manager',
      //         permissionKey: 'p:super-admin',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ActivityCategorizationManager',
      //         menuName: 'Categorizations',
      //         permissionKey: 'p:super-admin',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ActivityConfigurationLibrary',
      //         menuName: 'Configuration Library',
      //         permissionKey: 'p:super-admin',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ActivityLanguageManager',
      //         menuName: 'Activity Language Manager',
      //         permissionKey: 'p:super-admin',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   },
      //   {
      //     menuName: 'Activities',
      //     permissionKey: ['m:activities', 'p:super-admin'],
      //     class: 'nav-section explorer-themed',
      //     routes: [
      //       {
      //         routeName: 'ActivityExplorer',
      //         menuName: 'Activity Explorer',
      //         class: 'nav-item explorer-themed',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'WorksheetExplorer',
      //         menuName: 'Worksheet Explorer',
      //         class: 'nav-item explorer-themed',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'WeMoveExplorer',
      //         menuName: 'Video Explorer',
      //         class: 'nav-item explorer-themed',
      //         showAlert: false
      //       },
      //       // {
      //       //   routeName: 'ActivityDemos',
      //       //   menuName: 'Activity Demos'
      //       // },
      //       {
      //         routeName: 'ClinicGamePlayLists',
      //         menuName: 'PlayList Library',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   },
      //   {
      //     menuName: 'OVB Partner',
      //     permissionKey: ['p:app-demo-admin', 'p:app-tenant-group-admin'],
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'DemoCenter',
      //         menuName: 'Demo Center',
      //         permissionKey: 'p:app-demo-admin',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'SupportCenter',
      //         menuName: 'Support Center',
      //         permissionKey: 'p:app-tenant-group-admin',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   },
      //   {
      //     menuName: 'Clinic Administration',
      //     permissionKey: 'm:clinic-admin',
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'MySubscription',
      //         menuName: 'Subscriptions',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'AppTenantAppUsers',
      //         menuName: 'Users',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'Billing',
      //         menuName: 'Billing',
      //         permissionKey: this.showAdminNoSuperAdmin,
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ActivityLanguageManager',
      //         menuName: 'Activity Language Manager',
      //         permissionKey: 'p:manage-game-language',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //       // {
      //       //   routeName: 'AppTenantSettings',
      //       //   menuName: 'Settings'
      //       // }
      //     ]
      //   },
      //   {
      //     menuName: 'Site Administration',
      //     permissionKey: this.showSuperAdmin,
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'LicensePackManager',
      //         menuName: 'License Packs',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'AppTenantManager',
      //         menuName: 'App Tenants',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'GuestUsers',
      //         menuName: 'Guest Users',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ProjectManager',
      //         menuName: 'Workflow',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       // {
      //       //   routeName: 'ImageManager',
      //       //   menuName: 'Images'
      //       // },
      //       {
      //         routeName: 'ApplicationManager',
      //         menuName: 'Application',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'ManagerManager',
      //         menuName: 'Manager Manager',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'EulaManager',
      //         menuName: 'Saas Agreement Manager',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   },
      //   {
      //     menuName: 'Anchor Tenant',
      //     permissionKey: this.showSuperAdmin,
      //     class: 'nav-section',
      //     routes: [
      //       {
      //         routeName: 'MySubscription',
      //         menuName: 'Subscriptions',
      //         class: 'nav-item',
      //         showAlert: false
      //       },
      //       {
      //         routeName: 'AppTenantAppUsers',
      //         menuName: 'Users',
      //         class: 'nav-item',
      //         showAlert: false
      //       }
      //     ]
      //   }
      // ]
    }
  },
  computed: {
    ...mapGetters({
      helpCenterAlert: 'application/helpRequestNavAlert'
    }),
    navSections () {
      return [
        {
          menuName: 'Clinic',
          permissionKey: 'm:clinic',
          class: 'nav-section',
          routes: [
            {
              routeName: 'ClinicDashboard',
              menuName: 'Clinic Dashboard',
              class: 'nav-item',
              exact: true,
              showAlert: false
            },
            {
              routeName: 'ClinicPatients',
              menuName: 'Patient Roster',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ClinicTherapists',
              menuName: 'Therapist Roster',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ClinicNotificationManager',
              menuName: 'Notification Manager',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'QuickStart',
              menuName: 'OVB Quick Start',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ClinicSessionManager',
              menuName: 'Activity Sessions',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ActivityAssignmentReview',
              menuName: 'Assignment Review',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'HelpCenter',
              menuName: 'Help Center',
              class: 'nav-item',
              showAlert: false
            }
          ]
        },
        {
          menuName: 'Patient',
          permissionKey: 'm:patient',
          class: 'nav-section',
          routes: [
            {
              routeName: 'PatientDashboard',
              menuName: 'Patient Dashboard',
              class: 'nav-item',
              showAlert: false
            }
          ]
        },
        {
          menuName: 'OVB Silver',
          permissionKey: 'm:silver',
          class: 'nav-section',
          routes: [
            {
              routeName: 'GameRoomSilver',
              menuName: 'Game Room',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'GamesExplorer',
              menuName: 'Games Explorer',
              class: 'nav-item',
              showAlert: false
            }
          ]
        },
        {
          menuName: 'Clinic Trial',
          permissionKey: 'm:clinic-trial',
          class: 'nav-section',
          routes: [
            {
              routeName: 'ClinicTrial',
              menuName: 'Trial Dashboard',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ClinicPricing',
              menuName: 'Pricing',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'PurchaseUpgrade',
              menuName: 'Purchase Upgrade',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'GamesExplorer',
              menuName: 'Games Explorer',
              class: 'nav-item',
              showAlert: false
            }
          ]
        },
        {
          menuName: 'OVB Administration',
          permissionKey: this.showSuperAdmin,
          class: 'nav-section',
          routes: [
            {
              routeName: 'OvbDashboard',
              menuName: 'OVB Dashboard',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'SupportCenter',
              menuName: 'Support Center',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'DemoCenter',
              menuName: 'Demo Center',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'NotificationManager',
              menuName: 'Notification Manager',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'HelpCenterAdmin',
              menuName: 'Help Center',
              class: 'nav-item',
              showAlert: this.helpCenterAlert
            },
            // {
            //   routeName: 'MessagingManager',
            //   menuName: 'Messaging'
            // },
            // {
            //   routeName: 'HelpTopicManager',
            //   menuName: 'Help Manager'
            // },
            {
              routeName: 'ActivitySessionExplorer',
              menuName: 'Activity Sessions',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ActivityErrors',
              menuName: 'Activity Errors',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'OldCustomers',
              menuName: 'Old Customers',
              class: 'nav-item',
              showAlert: false
            }
          ]
        },
        {
          menuName: 'OVB Content',
          permissionKey: ['p:super-admin', 'p:ovb-activity-developer'],
          class: 'nav-section',
          routes: [
            {
              routeName: 'ActivityManager',
              menuName: 'Activity Manager',
              permissionKey: 'p:ovb-activity-developer',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'WeMoveManager',
              menuName: 'Video Manager',
              permissionKey: 'p:super-admin',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'WorksheetManager',
              menuName: 'Worksheet Manager',
              permissionKey: 'p:super-admin',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ActivityCategorizationManager',
              menuName: 'Categorizations',
              permissionKey: 'p:super-admin',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ActivityConfigurationLibrary',
              menuName: 'Configuration Library',
              permissionKey: 'p:super-admin',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ActivityLanguageManager',
              menuName: 'Activity Language Manager',
              permissionKey: 'p:super-admin',
              class: 'nav-item',
              showAlert: false
            }
          ]
        },
        {
          menuName: 'Activities',
          permissionKey: ['m:activities', 'p:super-admin'],
          class: 'nav-section explorer-themed',
          routes: [
            {
              routeName: 'ActivityExplorer',
              menuName: 'Activity Explorer',
              class: 'nav-item explorer-themed',
              showAlert: false
            },
            {
              routeName: 'WorksheetExplorer',
              menuName: 'Worksheet Explorer',
              class: 'nav-item explorer-themed',
              showAlert: false
            },
            {
              routeName: 'WeMoveExplorer',
              menuName: 'Video Explorer',
              class: 'nav-item explorer-themed',
              showAlert: false
            },
            // {
            //   routeName: 'ActivityDemos',
            //   menuName: 'Activity Demos'
            // },
            {
              routeName: 'ClinicGamePlayLists',
              menuName: 'PlayList Library',
              class: 'nav-item',
              showAlert: false
            }
          ]
        },
        {
          menuName: 'OVB Partner',
          permissionKey: ['p:app-demo-admin', 'p:app-tenant-group-admin'],
          class: 'nav-section',
          routes: [
            {
              routeName: 'DemoCenter',
              menuName: 'Demo Center',
              permissionKey: 'p:app-demo-admin',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'SupportCenter',
              menuName: 'Support Center',
              permissionKey: 'p:app-tenant-group-admin',
              class: 'nav-item',
              showAlert: false
            }
          ]
        },
        {
          menuName: 'Clinic Administration',
          permissionKey: 'm:clinic-admin',
          class: 'nav-section',
          routes: [
            {
              routeName: 'MySubscription',
              menuName: 'Subscriptions',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'AppTenantAppUsers',
              menuName: 'Users',
              class: 'nav-item',
              showAlert: false
            },
            // {
            //   routeName: 'Billing',
            //   menuName: 'Billing',
            //   permissionKey: this.showAdminNoSuperAdmin,
            //   class: 'nav-item',
            //   showAlert: false
            // },
            {
              routeName: 'ActivityLanguageManager',
              menuName: 'Activity Language Manager',
              permissionKey: 'p:manage-game-language',
              class: 'nav-item',
              showAlert: false
            }
            // {
            //   routeName: 'AppTenantSettings',
            //   menuName: 'Settings'
            // }
          ]
        },
        {
          menuName: 'Site Administration',
          permissionKey: this.showSuperAdmin,
          class: 'nav-section',
          routes: [
            {
              routeName: 'LicensePackManager',
              menuName: 'License Packs',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'AppTenantManager',
              menuName: 'App Tenants',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'GuestUsers',
              menuName: 'Guest Users',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ProjectManager',
              menuName: 'Workflow',
              class: 'nav-item',
              showAlert: false
            },
            // {
            //   routeName: 'ImageManager',
            //   menuName: 'Images'
            // },
            {
              routeName: 'ApplicationManager',
              menuName: 'Application',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'ManagerManager',
              menuName: 'Manager Manager',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'EulaManager',
              menuName: 'Saas Agreement Manager',
              class: 'nav-item',
              showAlert: false
            }
          ]
        },
        {
          menuName: 'Anchor Tenant',
          permissionKey: this.showSuperAdmin,
          class: 'nav-section',
          routes: [
            {
              routeName: 'MySubscription',
              menuName: 'Subscriptions',
              class: 'nav-item',
              showAlert: false
            },
            {
              routeName: 'AppTenantAppUsers',
              menuName: 'Users',
              class: 'nav-item',
              showAlert: false
            }
          ]
        }
      ]
    }
  },
  methods: {
    hasPermission (permissionKey) {
      return typeof (permissionKey) === 'function' ? permissionKey() : this.$userHasPermission(permissionKey)
    },
    showSuperAdmin () {
      return !this.$store.state.user.actualAppUserInfo && this.$userHasPermission('p:super-admin')
    },
    showAdminNoSuperAdmin () {
      return this.$userHasPermission('m:admin') && !this.$userHasPermission('p:super-admin')
    },
    showOldUsers () {
      return this.$store.state.user.showOldUsers
    },
    filteredRoutes (routes) {
      return routes.filter(r => {
        if (r.permissionKey) {
          return this.hasPermission(r.permissionKey)
        }
        if (r.permissionFn) {
          return r.permissionFn()
        }
        return true
      })
    }
  }
}
</script>
<template>
  <nav
    class="main-nav-menu"
  >
    <div v-for="ns in navSections"
      :key="ns.menuName"
    >
      <div
        :class="ns.class"
        v-if="hasPermission(ns.permissionKey)"
      >
        <div
          class="nav-section-label"
        >
          {{ns.menuName}}
        </div>
        <router-link
          v-for="r in filteredRoutes(ns.routes)"
          :key="r.menuName"
          :to="{ name: r.routeName }"
          :class="r.class"
          :exact="r.exact"
        >
          <div
            class="nav-label"
            :class="{'has-text-warning is-family-semibold': r.showAlert}"
          >
            {{r.menuName}} <span  v-if="r.showAlert" class="icon is-small ml-1 has-text-warning"><i class="mdi mdi-24px mdi-alert-box"/></span>
          </div>
        </router-link>
      </div>
    </div>

  </nav>
</template>

<style lang="scss" scoped>
.main-nav-menu {
  background-color: $text;
  $navBorderColor: hsla(255, 0%, 100%, .1);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 2rem;
  .nav-section {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    &.explorer-themed {
      background-color: #3E6880;
    }
  }
  .nav-section-label {
    color: $page-background;
    font-family: $family-light;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
  .nav-item {
    display: flex;
    border-top: 1px solid $navBorderColor;
    color: $page-background;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    line-height: 1rem;
    &.router-link-active {
      background-color: $watermelon;
    }
    &:last-child {
      border-bottom: 1px solid $navBorderColor;
    }
    &.explorer-themed {
      background-color: #3E6880;
      &.router-link-active {
        background-color: $watermelon;
      }
    }
  }
}
</style>
