import Vue from 'vue'
import VueApollo from 'vue-apollo'
import ApolloClient from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
// import { getInstance } from '@/auth/authWrapper'
import store from '@/store'
import router from '@/router'

Vue.use(VueApollo)
// let loading

const buildDevHeader = () => {
  // TO USE DEV HEADER, GO TO '/raw-user' AND USE THE 'dev header for vue' BUTTON.
  // INSERT THE VALUE BELOW
  // UNCOMMENT THAT LINE
  return {
    // 'ovb-dev-header': 'DEV-HEADER-FOR-VUE' ---- leave this line here for reference and use the next
    // patient Morton Subotnik
    // 'ovb-dev-header': 'eyJhcHBSb2xlIjoiYXBwX3VzciIsImluYWN0aXZlIjpmYWxzZSwidXNlcm5hbWUiOiJnbGVubi5iZXJyeStzdWJvdG5pa0BnbWFpbC5jb20iLCJob21lUGF0aCI6IlBhdGllbnREYXNoYm9hcmQiLCJsYXN0TmFtZSI6IlN1Ym90bmlrIiwibGlicmFyaWVzIjpbeyJpZCI6InZpc2lvbiIsIm5hbWUiOiJWaXNvbiIsImJhY2tncm91bmRNb2RlIjoibW9kYWxpdHkiLCJhbGxvd01vZGFsaXR5Q29uZmlndXJhdGlvbiI6dHJ1ZX1dLCJjb250YWN0SWQiOiIyOTkxMjU4MzA5MDUyMzM1OTE1IiwiZmlyc3ROYW1lIjoiTW9ydG9uIiwiYXBwVXNlcklkIjoiMjk5MTI1ODMwOTA2OTExMzEzMiIsImV4dEF1dGhJZCI6ImF1dGgwfDYzYWYyMjg4NzcwMWZlYTM4ZmUxNmRmZCIsInBlcm1pc3Npb25zIjpbIm06cGF0aWVudCIsImw6dmlzaW9uIiwicDphbGxvdy1jdXN0b20tY29uZmlndXJhdGlvbnMiLCJwOm1vZGFsaXR5Il0sInN1YnNpZGlhcmllcyI6W3siaWQiOiIyODA2MzE3MjY4MTIyNzMyMDUyIiwibmFtZSI6IkEyIFRlc3QgVGVuYW50OiBTdWJzaWRpYXJ5In1dLCJhcHBUZW5hbnRJZCI6IjI4MDYzMTcxNjc1NjAwOTkzNDQiLCJwZXJtaXNzaW9uS2V5IjoiVXNlciIsInJlY292ZXJ5RW1haWwiOiJnbGVubi5iZXJyeStzdWJvdG5pa0BnbWFpbC5jb20iLCJhcHBUZW5hbnROYW1lIjoiQTIgVGVzdCBUZW5hbnQiLCJsaWNlbnNpbmdTY29wZSI6WyIyODA2MzE3MTY3NTYwMDk5MzQ0Il0sImV4dEF1dGhCbG9ja2VkIjpmYWxzZSwiYWN0aXZpdHlEZXZpY2VJZCI6InVuZGVmaW5lZCIsImFjdHVhbEFwcFVzZXJJZCI6IjI5OTEyNTgzMDkwNjkxMTMxMzIiLCJhcHBVc2VyRGV2aWNlSWQiOiJ1bmtub3duIiwicHJlZmVycmVkVGltZXpvbmUiOiJQU1Q4UERUIiwicGFyZW50QXBwVGVuYW50SWQiOm51bGx9'
    // Glenn Admin
    // 'ovb-dev-header': 'eyJhcHBSb2xlIjoiYXBwX3NwX2FkbSIsImluYWN0aXZlIjpmYWxzZSwidXNlcm5hbWUiOiJnYmVycnkiLCJob21lUGF0aCI6Ik92YkRhc2hib2FyZCIsImxhc3ROYW1lIjoiQWRtaW4iLCJsaWJyYXJpZXMiOlt7ImlkIjoidmlzaW9uIiwibmFtZSI6IlZpc29uIiwiYmFja2dyb3VuZE1vZGUiOiJtb2RhbGl0eSIsImFsbG93TW9kYWxpdHlDb25maWd1cmF0aW9uIjp0cnVlfSx7ImlkIjoidmlzaW9uIiwibmFtZSI6IlZpc29uIiwiYmFja2dyb3VuZE1vZGUiOiJtb2RhbGl0eSIsImFsbG93TW9kYWxpdHlDb25maWd1cmF0aW9uIjp0cnVlfSx7ImlkIjoib2NjdXBhdGlvbmFsIiwibmFtZSI6Ik9jY3VwYXRpb25hbCIsImJhY2tncm91bmRNb2RlIjoic3RpbXVsdXMiLCJhbGxvd01vZGFsaXR5Q29uZmlndXJhdGlvbiI6ZmFsc2V9LHsiaWQiOiJzaWx2ZXIiLCJuYW1lIjoiU2lsdmVyIiwiYmFja2dyb3VuZE1vZGUiOiJub25lIiwiYWxsb3dNb2RhbGl0eUNvbmZpZ3VyYXRpb24iOmZhbHNlfSx7ImlkIjoidW5ib3VuZCIsIm5hbWUiOiJVbmJvdW5kIiwiYmFja2dyb3VuZE1vZGUiOiJzdGltdWx1cyIsImFsbG93TW9kYWxpdHlDb25maWd1cmF0aW9uIjpmYWxzZX1dLCJjb250YWN0SWQiOiIyNzkzODYzNzM4MTUzNzYzODUzIiwiZmlyc3ROYW1lIjoiR2xlbm4iLCJhcHBVc2VySWQiOiIwMUYwWFpHTUo1RldHSlNXUDk0NDBQQkpEQiIsImV4dEF1dGhJZCI6ImF1dGgwfDYwMzU4Yjc4OThlM2U1MDA2YTlkNzZjYSIsInBlcm1pc3Npb25zIjpbInA6b3ZiLWFjdGl2aXR5LWRldmVsb3BlciIsImw6dmlzaW9uIiwicDphbGxvdy1jdXN0b20tY29uZmlndXJhdGlvbnMiLCJwOm1vZGFsaXR5IiwicDpzdXBlci1hZG1pbiIsIm06YWRtaW4iLCJwOmFwcC10ZW5hbnQtc2NvcGUiLCJwOm1hbmFnZS1zdWJzaWRpYXJpZXMiLCJwOmRlbW8iLCJwOm1vZGFsaXR5IiwicDpjcmVhdGUtYW5ub3VuY2VtZW50IiwibDp2aXNpb24iLCJsOm9jY3VwYXRpb25hbCIsImw6c2lsdmVyIiwibDp1bmJvdW5kIiwicDp0dXRvcmlhbCJdLCJzdWJzaWRpYXJpZXMiOltdLCJhcHBUZW5hbnRJZCI6IjI3OTM4NjM3Mzc4ODUzMjgzODgiLCJwZXJtaXNzaW9uS2V5IjoiU3VwZXJBZG1pbiIsInJlY292ZXJ5RW1haWwiOiJnbGVubi5iZXJyeUBnbWFpbC5jb20iLCJhcHBUZW5hbnROYW1lIjoiQW5jaG9yIFRlbmFudCIsImxpY2Vuc2luZ1Njb3BlIjpbIjI3OTM4NjM3Mzc4ODUzMjgzODgiXSwiZXh0QXV0aEJsb2NrZWQiOmZhbHNlLCJhY3Rpdml0eURldmljZUlkIjoidW5rbm93biIsImFjdHVhbEFwcFVzZXJJZCI6IjAxRjBYWkdNSjVGV0dKU1dQOTQ0MFBCSkRCIiwiYXBwVXNlckRldmljZUlkIjoidW5rbm93biIsInByZWZlcnJlZFRpbWV6b25lIjoiUFNUOFBEVCIsInBhcmVudEFwcFRlbmFudElkIjpudWxsfQ=='
  }
}

const buildScopeHeader = () => {
  if (store.state.user.scopeAppTenant) {
    // const str = JSON.stringify(store.state.user.scopeAppTenant)
    const str = JSON.stringify(
      {
        id: store.state.user.scopeAppTenant.id,
        parentAppTenantId: store.state.user.scopeAppTenant.parentAppTenantId
      }
    )
    const encoded = btoa(unescape(encodeURIComponent(str)))
    return {
      fnbs: encoded
    }
  } else {
    return {}
  }
}

const buildImpersonationHeader = () => {
  if (store.state.user.actualAppUserInfo) {
    const str = JSON.stringify(store.state.user.userInfo)
    const encoded = btoa(unescape(encodeURIComponent(str)))
    return {
      fnbi: encoded
    }
  } else {
    return {}
  }
}

const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  // console.log('graphql error', JSON.stringify(graphQLErrors, null, 2))
  // console.log('networkError', JSON.stringify(networkError, null, 2))
  if (networkError && networkError.statusCode === 401) {
    router.push({ name: 'SessionExpired' })
  } else if (graphQLErrors) {
    store
      .dispatch('recordException', graphQLErrors
        .map(e => {
          return {
            ...e,
            operationName: operation.operationName,
            variables: operation.variables
          }
        })
      )
  }
})

const authLink = setContext((_, { headers }) => {
  const scopeHeader = buildScopeHeader()
  const impersonationHeader = buildImpersonationHeader()
  const timeZoneHeader = { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }
  const appUserDeviceIdHeader = { appUserDeviceId: store.state.user.userDevice ? store.state.user.userDevice.appUserDevice ? store.state.user.userDevice.appUserDevice.id : 'unknown' : 'unknown' }
  const activityDeviceIdHeader = { activityDeviceId: store.state.user.userDevice ? store.state.user.userDevice.id : 'unknown' }
  const devHeader = buildDevHeader()
  return {
    headers: {
      ...headers,
      // ...authorizationHeader,
      ...scopeHeader,
      ...impersonationHeader,
      ...timeZoneHeader,
      ...appUserDeviceIdHeader,
      ...activityDeviceIdHeader,
      ...devHeader
    }
  }

  // return getAuth()
  //   .then(authorization => {
  //     const authorizationHeader = authorization ? { authorization } : {}
  //     const scopeHeader = buildScopeHeader()
  //     const impersonationHeader = buildImpersonationHeader()
  //     const timeZoneHeader = { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }
  //     const appUserDeviceIdHeader = { appUserDeviceId: store.state.user.userDevice ? store.state.user.userDevice.appUserDevice ? store.state.user.userDevice.appUserDevice.id : 'unknown' : 'unknown' }
  //     const activityDeviceIdHeader = { activityDeviceId: store.state.user.userDevice ? store.state.user.userDevice.id : 'unknown' }
  //     const devHeader = buildDevHeader()
  //     return {
  //       headers: {
  //         ...headers,
  //         ...authorizationHeader,
  //         ...scopeHeader,
  //         ...impersonationHeader,
  //         ...timeZoneHeader,
  //         ...appUserDeviceIdHeader,
  //         ...activityDeviceIdHeader,
  //         ...devHeader
  //       }
  //     }
  //   })
})

// All the graphql requests will be made at yourdomain.com/graphql
const httpLink = new HttpLink({
  uri: '/graphql'
})

// Cache implementation
const cache = new InMemoryCache()

// We give this to the graphql client
const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache
})

// And we reference this client needed by vue-apollo
export default new VueApollo({
  defaultClient: apolloClient,
  // Default 'apollo' definition
  defaultOptions: {
    // See 'apollo' definition
    // For example: default query options
    $query: {
      loadingKey: 'loading',
      fetchPolicy: 'cache-and-network'
    }
  },
  // Watch loading state for all queries
  // See 'Smart Query > options > watchLoading' for detail
  // watchLoading (isLoading, countModifier) {
  //   loading += countModifier
  //   // console.log('Global loading', loading, countModifier)
  // },
  // Global error handler for all smart queries and subscriptions
  errorHandler (error) {
    const msg = error.toString()
    return msg
    // console.log('Global error handler', error.toString())
  },
  // Globally turn off prefetch ssr
  prefetch: false
})
